<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre Rol</span>
            <v-text-field
              v-model="roles.name"
              :error-messages="validationErrors.name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import { storeRoles, updateRoles } from "@/services/configService";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newRole.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      territoryTypes: [],
      disharmonyTypes: [],
      roles: {
        name: "",
        id: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      roles: {
        name: { required },
      },
    };
  },
  methods: {
    addAffected(affected) {
      this.roles.affecteds.push(affected);
    },
    removeAffected(affected) {
      console.log(affected);
      const index = this.roles.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.roles.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.roles.affecteds.splice(index, 1);
    },
    async save() {
      this.$v.roles.$touch();
      if (this.$v.roles.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.roles.name,
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateRoles(this.roles.id, eventRequestData)
            : await storeRoles(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire("Registro guardado correctamente", "", "success");

        this.hideLoader();

        this.$router.push({
          name: "generalConfig",
        });
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.roles = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {},
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.roles.name.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
